<template>
    <div class="submitBox">
        <div class="submitBox1">
            <div class="orderTop">
                <div class="top_1">
                    <div class="img">
                        <img src="../../assets/homePage/tijiao.png" alt="">
                    </div>
                    <div class="succ">
                        <p>订单提交成功</p>
                        <p>您的报价已受理，请等待业务员报价！</p>
                    </div>
                </div>
                <div class="top_2">
                    <p>下单时间</p>
                    <p>2022/10/13 10.00</p>
                </div>
                <div class="top_3">
                    <p>订单号</p>
                    <p>123456</p>
                </div>
                <div class="top_4">
                    <p>订单类型</p>
                    <p>海运</p>
                </div>
            </div>

            <div class="tips">温馨提示：您的订单正在处理，请等待我们的报价，您可以在询价界面查看报价，也可以在个人中心-询价订单中查看！</div>

            <div class="subContent">
                <div class="xjButtom">
                    <ul>
                        <li class="lis_1">
                            <div class="startingPoint">
                                <div class="radius_1">起</div>
                                <div class="radius_2"></div>
                                <div class="radius_3"></div>
                                <div class="radius_4"></div>
                                <div class="radius_5">到</div>
                            </div>
                            <div class="address">
                                <p>成都</p>
                                <p>莫斯科</p>
                            </div>
                        </li>
                        <li class="lis_2">
                            <div>
                                <p>袁星 <span>1234567890</span></p>
                                <p>浙江省宁波市鄞州区会展中心</p>
                            </div>
                            <div>
                                <p>袁星 <span>1234567890</span></p>
                                <p>浙江省宁波市鄞州区会展中心</p>
                            </div>
                        </li>
                        <li class="lis_3">
                            <div>
                                <p>2095305329@qq.com</p>
                            </div>
                            <div>
                                <p>2095305329@qq.com</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>到门地址</p>
                                <p>浙江省宁波市鄞州区会展中心10号馆</p>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="notice">
                    <div class="notifier noticeSmall">
                        <p>通知人</p>
                        <p><span>袁星</span>&emsp;<span>1234567890</span></p>
                        <p>浙江省宁波市鄞州区会展中心</p>
                    </div>
                    <div class="packingContact noticeSmall">
                        <p>装箱联系人</p>
                        <p><span>袁星</span><span>1234567890</span></p>
                        <p>浙江省宁波市鄞州区会展中心</p>
                    </div>
                    <div class="packingContact noticeSmall">
                        <p>装箱联系人</p>
                        <p><span>袁星</span><span>1234567890</span></p>
                        <p>浙江省宁波市鄞州区会展中心</p>
                    </div>
                </div>

                <div class="binType">
                    <div class="binTypeS">
                        <p>仓位类型</p>
                        <p>COC</p>
                    </div>
                    <div class="binTypeS">
                        <p>箱型</p>
                        <p>20GP</p>
                    </div>
                    <div class="binTypeS">
                        <p>箱量</p>
                        <p>20</p>
                    </div>
                </div>

                <div class="goodsList">
                    <div class="goodsItem">
                        <div class="goodsTitle">物品1</div>
                        <div class="items">
                            <ul>
                                <li>
                                    <p>物品名</p>
                                    <p>香蕉</p>
                                </li>
                                <li>
                                    <p>毛重</p>
                                    <p>208</p>
                                </li>
                                <li>
                                    <p>体积</p>
                                    <p>30.15</p>
                                </li>
                                <li>
                                    <p>件数</p>
                                    <p>465</p>
                                </li>
                                <li>
                                    <p>H.S.CODE</p>
                                    <p>123456</p>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="subBtn">
                <div class="cancelTheOrder">撤单</div>
                <div class="viewOrder">查看订单</div>

            </div>
        </div>


        <Footer_Compontent />
    </div>
</template>
<!--  -->
<script>
import Footer_Compontent from '../../components/Footer_Compontent.vue';
export default {
    name: "submitSuccess",
    components: { Footer_Compontent },
}
</script>

<style lang="scss" scoped>
.submitBox {
    margin-top: 10px;
    border-radius: 8px 8px 8px 8px;

    .submitBox1 {
        width: 1200px;
        margin: 32px auto;
        padding: 32px 32px 32px;
        background: #FFFFFF;
        overflow: hidden;
    }

    .orderTop {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top_1 {
            display: flex;
            align-items: center;

            .img>img {
                width: 74px;
                height: 63px;
            }

            .succ {
                margin-left: 24px;

                p:nth-child(1) {
                    font-size: 18px;
                    font-weight: bold;
                    color: #000000;
                }

                p:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 12px;
                }
            }
        }

        .top_2,
        .top_3,
        .top_4 {
            font-size: 14px;
            font-weight: 400;
            color: #999999;

            p:nth-child(2) {
                margin-top: 15px;
                color: #333333;
            }
        }


    }

    .tips {
        width: 1136px;
        height: 40px;
        background: #FFF2E5;
        margin-top: 21px;
        font-size: 12px;
        font-weight: 400;
        color: #D4252C;
        text-align: center;
        line-height: 40px;
    }

    .subContent {
        width: 1136px;
        // height: 568px;
        border: 1px solid #999999;
        margin-top: 24px;
        padding: 0 48px 32px;

        .xjButtom {
            // width: 1136px;
            height: 175px;
            // border: 1px solid #999999;
            margin-top: 24px;
            padding: 32px 0;
            border-bottom: 1px dashed #999999;


            ul {
                display: flex;
                justify-content: space-around;

                li {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }

                .lis_1 {
                    display: flex;

                    .startingPoint {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        height: 112px;

                        .radius_1,
                        .radius_5 {
                            width: 36px;
                            height: 36px;
                            background: #1890FF;
                            border-radius: 50%;
                            font-size: 12px;
                            font-weight: 400;
                            color: #FFFFFF;
                            text-align: center;
                            line-height: 36px;
                        }

                        .radius_5 {
                            background: #FFC13B;
                        }

                        .radius_2,
                        .radius_3,
                        .radius_4 {
                            width: 4px;
                            height: 4px;
                            background: #999999;
                            border-radius: 50%;
                        }
                    }

                    .address {
                        margin-left: 24px;
                        height: 112px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 11px 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: #333333;
                    }
                }

                .lis_2,
                .lis_3 {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;

                    p:nth-child(2) {
                        margin-top: 5px;
                    }
                }
            }
        }

        .notice {
            display: flex;
            margin-top: 40px;
            padding-bottom: 40px;
            border-bottom: 1px dashed #999999;

            .noticeSmall {
                flex: 1;


                p:nth-child(1) {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333333;
                }

                p:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 10px;
                }

                p:nth-child(3) {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 10px;
                }
            }
        }

        .binType {
            display: flex;
            text-align: left;
            margin-top: 40px;

            p:nth-child(1) {
                font-size: 14px;
                font-weight: bold;
                color: #333333;
            }

            p:nth-child(2) {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
            }

            .binTypeS {
                margin-left: 200px;
            }

            .binTypeS:nth-child(1) {
                margin-left: 0;
            }
        }

        .goodsList {
            margin-top: 40px;

            .goodsTitle {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }

            .items {
                width: 1040px;
                height: 82px;
                background: #FFFFFF;
                box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
                border-radius: 10px;
                margin-top: 8px;

                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 16px 20px;

                    li {
                        // margin-top: 16px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    .subBtn {
        display: flex;
        text-align: center;
        line-height: 40px;
        float: right;
        margin-top: 40px;

        .cancelTheOrder {
            width: 150px;
            height: 40px;
            border-radius: 30px 30px 30px 30px;
            border: 1px solid #1890FF;
            font-size: 18px;
            font-weight: 400;
            color: #1890FF;
            margin-right: 32px;
        }

        .viewOrder {
            width: 150px;
            height: 40px;
            background: #1890FF;
            border-radius: 30px 30px 30px 30px;
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}

.footer {
    margin-top: 120px;
}
</style>